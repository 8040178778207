import React, { useState } from 'react'
import './Faq.css';
import { IoIosArrowRoundForward } from "react-icons/io";
import { GoPlus } from "react-icons/go";
import { FaMinus } from "react-icons/fa6";
const Faq = () => {

    const [openIndex, setOpenIndex] = useState(null);

    const toggleAccordion = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };
    const questions = [
        {
            question: 'What does Sonaroid do?',
            answer: 'Sonaroid is a provider of market intelligence in the African energy industry. Our web solution aims to improve access to quality data and analytics. Our bespoke services offer corporate entities improved project execution by leveraging data-driven strategies.'
        },

        {
            question: 'What kind of resources can I access on Sonaroid\'s platform?',
            answer: '- You can access authentic datasets that are bias-free so you can focus on crunching the numbers and steering your business in the right direction \n - We have also taken an extra step to convert the data to insights with visually appealing Infographics \n - We also have a variety of vetted documents from energy professionals around the world to provide you with a wide range of perspectives to broaden your knowledge \n - We also aggregate news articles from across the continent to keep you informed and up to date.'
        },

        {
            question: 'What areas does Sonaroid cover?',
            answer: 'Sonaroid\'s major focus is the African energy industry, Our research and analytics cover the full value chain of active energy sources in the continent including oil, gas, hydropower, solar, wind, geothermal, biomass, and green hydrogen.'
        },
        {
            question: 'How do I access Sonaroid\'s resources?',
            answer: 'We are launching very soon and we can\'t wait to share our resources with you. Be the first to access these resources by joining the waitlist. The amazing thing about our waitlist is that there is no waiting! With our periodic newsletters, you will start to receive insights from our resources even before we officially launch.'
        },


    ];
    return (
        <section className="Faq-container">
            <div className="Faq-left">
                <h1>FAQs</h1>
                <p>Do you want to know more? Browse through our FAQs to get an instant answer.
                </p>
                <div>
                    <div>
                        <a href="/contact">Contact Us</a>
                        {/* <a href="/#">More Questions</a> */}
                        <IoIosArrowRoundForward />
                    </div>
                    {/* <a href="/contact">Contact Us</a> */}
                </div>

            </div>



            <div className="Faq-right">
                {questions.map((item, index) => (
                    <div key={index} className="accordion-item">
                        <div className={`accordion-header ${openIndex === index ? 'open' : ''}`} onClick={() => toggleAccordion(index)}>
                            <div className={`accordion-question ${openIndex === index ? 'open' : ''}`}>
                                {item.question}
                            </div>

                            {openIndex === index ?
                                <div className="accordion-icon">
                                    <FaMinus style={{ fontSize: '20px' }} />

                                </div>
                                :
                                <div className="accordion-icon">
                                    <GoPlus style={{ fontSize: '20px' }} />
                                </div>
                            }
                        </div>

                        <div className={`accordion-content ${openIndex === index ? 'open' : ''}`}>
                            <p>
                                {item.answer.split('\n').map((line, idx) => (
                                    <span key={idx}>
                                        {line}
                                        <br />
                                    </span>
                                ))}
                            </p>
                        </div>

                    </div>
                ))}
            </div>
        </section>
    )
}

export default Faq
